import axios from "axios";

class BookingService {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL;
    this.authToken = process.env.VUE_APP_API_TOKEN;
  }

  /**
   * Fetch booking.
   *
   * @param hash
   * @returns {Promise<null|any>}
   */
  async getBooking(hash) {
    try {
      const response = await axios.get(
        this.baseUrl + "/website/bookings/" + hash,
        {
          headers: { Authorization: `Bearer ${this.authToken}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }

  /**
   * Fetch booking.
   *
   * @param hash
   * @param payload
   * @returns {Promise<null|any>}
   */
  async updateBooking(hash, payload) {
    try {
      const response = await axios.put(
        this.baseUrl + "/website/bookings/" + hash,
        payload,
        {
          headers: { Authorization: `Bearer ${this.authToken}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }
}

export default BookingService;
