<template>
  <div class="flex text-left p-3">
    <div class="flex-1">
      <div class="mb-4">
        <div class="font-roboto-bold">{{ $t("reference") }}</div>
        <div>{{ booking.refid }}</div>
      </div>
      <div class="mb-4">
        <div class="font-roboto-bold">{{ $t("name") }}</div>
        <div>
          {{ booking.visitor.first_name }}
          {{ booking.visitor.last_name }}
        </div>
      </div>
      <div class="mb-4">
        <div class="font-roboto-bold">{{ $t("email") }}</div>
        <div>{{ booking.visitor.email }}</div>
      </div>
      <div class="mb-0">
        <div class="font-roboto-bold">{{ $t("groupName") }}</div>
        <div>{{ booking.group_name ? booking.group_name : "-" }}</div>
      </div>
    </div>
    <div class="flex-1">
      <div class="mb-4">
        <div class="font-roboto-bold">{{ $t("event") }}</div>
        <div>{{ booking.event.name }}</div>
      </div>
      <div class="mb-4">
        <div class="font-roboto-bold">{{ $t("date") }}</div>
        <div>{{ formatDate(booking.event.date) }}</div>
      </div>
      <div class="mb-4">
        <div class="font-roboto-bold">{{ $t("time") }}</div>
        <div>{{ formatTime(booking.event.time) }}</div>
      </div>
      <div class="mb-0">
        <div class="font-roboto-bold">{{ $t("participants") }}</div>
        <div>
          {{ booking.num_adults }} {{ $t("adults") }} /
          {{ booking.num_children > 0 ? booking.num_children : "0" }}
          {{ $t("children") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BookingDetails",

  props: {
    booking: {
      type: Object,
      required: true,
    },
  },

  methods: {
    /**
     * Format to date time.
     *
     * @param value
     * @returns {*}
     */
    formatDate(value) {
      return moment(value).format("DD.MM.YYYY");
    },

    /**
     * Format to date time.
     *
     * @param value
     * @returns {*}
     */
    formatTime(value) {
      return moment(value, "HH:mm:ss").format("HH:mm") + " Uhr";
    },
  },
};
</script>

<style scoped></style>
