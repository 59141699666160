<template>
  <div class="fgg-container" v-if="!loading">
    <div class="text-center mt-4 mb-8">
      <img src="../assets/Logo_Dark.svg" alt="Logo" class="w-32 mx-auto" />
    </div>
    <div class="mb-4 mx-10 mobile:mx-0">
      <h1 class="uppercase mb-6">{{ $t("title") }}</h1>
      <p v-html="$t('text')"></p>
    </div>
    <div class="flex justify-end">
      <button
        :class="[
          'btn',
          !showGrid ? 'btn-secondary' : 'btn-default',
          'mobile:w-full',
        ]"
        @click="showGrid = false"
      >
        {{ $t("labelDetails") }}
      </button>
      <button
        :class="[
          'btn',
          showGrid ? 'btn-secondary' : 'btn-default',
          'ml-4',
          'mobile:w-full',
          'mobile:ml-0',
        ]"
        @click="showGrid = true"
      >
        {{ $t("labelEditor") }}
      </button>
    </div>
  </div>
  <div class="fgg-container" v-if="!loading">
    <div class="v-grid-container">
      <div v-show="showGrid">
        <div class="text-left text-base">
          <strong>{{ $t("hint") }}:</strong> * = f/m/n
        </div>
        <v-grid
          ref="vgrid"
          theme="material"
          :source="rows"
          :columns="columns"
          :column-types="plugins"
          :auto-size-column="{ mode: 'autoSizeAll' }"
          :can-focus="true"
          :resize="true"
          :range="true"
          :frame-size="0"
          @beforeeditstart="beforeEditStart"
          @focusout="commitCell"
        />
        <div class="text-left mt-10">
          <label class="font-bold block mb-2">{{ $t("comments") }}</label>
          <textarea
            v-model="comments"
            rows="3"
            class="
              w-full
              bg-transparent
              border border-accent-700
              rounded-lg
              outline-none
              p-4
            "
          ></textarea>
        </div>
        <button
          class="btn btn-primary mt-4 w-48"
          @click="save()"
          :disabled="!isDirty || !isValid || isSaving"
        >
          <svg
            v-if="isSaving"
            role="status"
            class="inline mr-2 w-5 h-5 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#f0e9de"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#3d2706"
            />
          </svg>
          {{ $t("save") }}
        </button>
      </div>
      <div v-show="!showGrid">
        <booking-details :booking="booking" />
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useToast, POSITION } from "vue-toastification";
import BookingService from "../services/bookingService";
import ValidationService from "../services/validationService";
import VGrid from "@revolist/vue3-datagrid";
import SelectTypePlugin from "@revolist/revogrid-column-select";
import BookingDetails from "../components/BookingDetails";

export default {
  name: "Booking",

  components: {
    BookingDetails,
    VGrid,
  },

  setup() {
    const route = useRoute();

    return {
      hash: route.params.hash,
      language: route.params.language,
      bookingService: new BookingService(),
      toast$: useToast(),
    };
  },

  data: () => ({
    loading: true,
    booking: null,
    showGrid: false,
    isDirty: false,
    isInit: 0,
    isSaving: false,

    comments: null,
    plugins: { select: new SelectTypePlugin() },
    gRowIndex: null,
    gColName: null,
    columns: [
      {
        prop: "number",
        name: "#",
        columnType: "text",
        size: 60,
        readonly: true,
        cellProperties: ({ model }) => {
          return {
            class: {
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        prop: "gender",
        name: "Geschlecht",
        columnType: "text",
        size: 110,
        readonly: (data) => data.model.is_visitor,
        cellProperties: ({ model }) => {
          const valid = ValidationService.validateGender(model.gender);
          model.validGender = valid;
          return {
            class: {
              error: !valid,
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        prop: "first_name",
        name: "Vorname",
        columnType: "text",
        size: 160,
        readonly: (data) => data.model.is_visitor,
        cellProperties: ({ model }) => {
          const valid = ValidationService.validateRequired(model.first_name);
          model.validFirstName = valid;
          return {
            class: {
              error: !valid,
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        prop: "last_name",
        name: "Nachname",
        columnType: "text",
        size: 160,
        readonly: (data) => data.model.is_visitor,
        cellProperties: ({ model }) => {
          const valid = ValidationService.validateRequired(model.last_name);
          model.validLastName = valid;
          return {
            class: {
              error: !valid,
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        prop: "email",
        name: "E-Mail",
        columnType: "email",
        size: 331,
        readonly: (data) => data.model.is_visitor,
        cellProperties: ({ model, data }) => {
          const valid =
            ValidationService.validateEmail(model.email) &&
            ValidationService.uniqueEmail(model.email, data);
          model.validEmail = valid;
          return {
            class: {
              error: !valid,
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        prop: "zip",
        name: "PLZ",
        columnType: "text",
        size: 120,
        readonly: (data) => data.model.is_visitor,
        cellProperties: ({ model }) => {
          const valid = ValidationService.validateRequired(model.zip);
          model.validZip = valid;
          return {
            class: {
              error: !valid,
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        prop: "year_of_birth",
        name: "Jahrgang",
        columnType: "text",
        size: 120,
        readonly: (data) => data.model.is_visitor,
        cellProperties: ({ model }) => {
          const valid = ValidationService.validateYear(model.year_of_birth);
          model.validDateOfBirth = valid;
          return {
            class: {
              error: !valid,
              readonly: model.is_visitor,
            },
          };
        },
      },
      {
        name: "",
        columnType: "text",
        size: 50,
        readonly: true,
        cellTemplate: (createElement, props) => {
          const complete =
            props.model.validGender &&
            props.model.validFirstName &&
            props.model.validLastName &&
            props.model.validEmail &&
            props.model.validZip &&
            props.model.validDateOfBirth;
          return createElement("div", {
            class: {
              complete: complete,
              incomplete: !complete,
            },
          });
        },
        cellProperties: ({ model }) => {
          return {
            class: {
              readonly: model.is_visitor,
            },
          };
        },
      },
    ],
    rows: [],
  }),

  watch: {
    rows: {
      deep: true,
      handler() {
        if (this.isInit > 2) {
          this.isDirty = true;
        }
        this.isInit++;
      },
    },
    comments() {
      if (this.isInit > 2) {
        this.isDirty = true;
      }
      this.isInit++;
    },
  },

  computed: {
    isValid() {
      return ValidationService.uniqueEmails(this.rows);
    },
  },

  async mounted() {
    this.loading = true;
    if (this.hash) {
      this.$i18n.locale = this.language;
      this.columns.find((col) => col.prop === "gender").name =
        this.$t("gender");
      this.columns.find((col) => col.prop === "first_name").name =
        this.$t("firstName");
      this.columns.find((col) => col.prop === "last_name").name =
        this.$t("lastName");
      this.columns.find((col) => col.prop === "email").name = this.$t("email");
      this.columns.find((col) => col.prop === "zip").name = this.$t("zip");
      this.columns.find((col) => col.prop === "year_of_birth").name =
        this.$t("yearOfBirth");
      try {
        this.booking = await this.bookingService.getBooking(
          this.hash
        );
        this.comments = this.booking.comments;
        this.rows.push(this.mapRow(this.booking.visitor, 1, true));
        this.booking.attendants.forEach((attendant, index) => {
          this.rows.push(this.mapRow(attendant, index + 2, false));
        });
        const numMissing =
          this.booking.num_adults - 1 - this.booking.attendants.length;
        for (let i = 0; i < numMissing; i++) {
          this.rows.push(
            this.mapRow(
              { gender: null },
              this.booking.attendants.length + 2 + i
            )
          );
        }
        this.loading = false;
      } catch (error) {
        this.toast$.error("Es gab einen Fehler bei der Serverabfrage.", {
          position: POSITION.BOTTOM_CENTER,
        });
        this.loading = false;
      }
    } else {
      this.toast$.error("ID ist ungültig.", {
        position: POSITION.BOTTOM_CENTER,
      });
    }
  },

  methods: {
    /**
     * Map a row entity.
     *
     * @param entity
     * @param number
     * @param isCustomer
     */
    mapRow(entity, number, isCustomer = false) {
      return {
        number: number,
        gender: entity.gender,
        first_name: entity.first_name,
        last_name: entity.last_name,
        email: entity.email,
        zip: entity.zip,
        date_of_birth: entity.date_of_birth,
        year_of_birth: entity.date_of_birth
          ? entity.date_of_birth.split("-")[0]
          : null,
        is_visitor: isCustomer,

        validGender: false,
        validFirstName: false,
        validLastName: false,
        validEmail: false,
        validZip: false,
        validDateOfBirth: false,
      };
    },

    /**
     * Save changes.
     */
    async save() {
      this.isSaving = true;
      const attendants = this.rows
        .filter((a) => !a.is_visitor)
        .map((a) => ({
          gender: a.gender,
          first_name: a.first_name,
          last_name: a.last_name,
          email: a.email,
          zip: a.zip,
          date_of_birth: a.year_of_birth
            ? a.date_of_birth
              ? a.year_of_birth +
                "-" +
                a.date_of_birth.split("-").splice(1).join("-")
              : a.year_of_birth + "-01-01"
            : null,
          is_visitor: a.is_visitor,
        }));
      const payload = {
        comments: this.comments,
        attendants,
      };
      try {
        await this.bookingService.updateBooking(this.hash, payload);
        this.toast$.success("Die Änderungen wurden erfolgreich gespeichert.", {
          position: POSITION.BOTTOM_CENTER,
        });
        this.isDirty = false;
        this.isSaving = false;
      } catch (error) {
        this.isSaving = false;
        this.toast$.error(
          "Es gab einen Fehler bei der Übermittlung der Daten.",
          {
            position: POSITION.BOTTOM_CENTER,
          }
        );
      }
    },

    /**
     * Before edit start callback.
     *
     * @param event
     */
    beforeEditStart(event) {
      this.gRowIndex = event.detail.rowIndex;
      this.gColName = event.detail.prop;
    },

    /**
     * Commit cell.
     *
     * @param event
     * @returns {Promise<void>}
     */
    async commitCell(event) {
      if (event.target.value) {
        let viewData = await this.$refs.vgrid.$el.getVisibleSource();
        viewData[this.gRowIndex][this.gColName] = event.target.value;
        this.$refs.vgrid.$el.refresh();
      }
    },
  },
};
</script>

<style lang="scss">
.v-grid-container {
  @apply text-center;
  @apply bg-accent-100;
  @apply rounded-lg;
  @apply p-8;

  revo-grid {
    @apply text-sm;
    @apply w-full;

    .main-viewport {
      @apply h-auto;
    }

    revogr-edit {
      input {
        @apply border-none;
        @apply border-0;
        padding: 0 15px;

        &:focus,
        &:active {
          @apply outline-none;
        }
      }
    }

    .rgCell {
      @apply text-primary-500;
      @apply text-sm;
      @apply leading-10;

      &.error {
        @apply bg-red-200;
      }

      &.disabled {
        @apply font-bold;
        @apply bg-accent-600;
      }

      .complete {
        background-image: url("../assets/check.svg");
        @apply bg-cover;
        @apply mt-3;
        @apply w-6;
        @apply h-6;
      }

      .incomplete {
        background-image: url("../assets/close.svg");
        @apply bg-cover;
        @apply mt-3;
        @apply w-6;
        @apply h-6;
      }
    }
  }
}

@screen mobile {
  .v-grid-container {
    @apply p-3;
  }
}
</style>
