class ValidationService {
  /**
   * Validate required.
   * @param value
   * @returns {boolean}
   */
  static validateRequired(value) {
    return !!value;
  }

  /**
   * Validate email.
   *
   * @param email
   * @returns {*}
   */
  static validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  /**
   * Validate gender.
   *
   * @param gender
   * @returns {boolean}
   */
  static validateGender(gender) {
    return ["m", "n", "f", "Herr", "Frau", "Neutral"].indexOf(gender) >= 0;
  }

  /**
   * Check if email is unique.
   *
   * @param email
   * @param rows
   * @returns {boolean}
   */
  static uniqueEmail(email, rows) {
    const emailAddresses = rows.map((row) => row.email).filter((row) => !!row);
    return emailAddresses.reduce((a, v) => (v === email ? a + 1 : a), 0) < 2;
  }

  /**
   * Validate year.
   *
   * @param year
   * @returns {*}
   */
  static validateYear(year) {
    return parseInt(year) >= 1900 && parseInt(year) <= 3000;
  }

  /**
   * Check if an email is unique
   *
   * @param rows
   * @returns {boolean}
   */
  static uniqueEmails(rows) {
    const emailAddresses = rows.map((row) => row.email).filter((row) => !!row);

    return (
      emailAddresses.filter(
        (email, index) => emailAddresses.indexOf(email) !== index
      ).length === 0
    );
  }
}

export default ValidationService;
